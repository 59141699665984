import zh from './lang/zh'
import de from './lang/de'
import jp from './lang/jp'
import en from './lang/en'
import es from './lang/es'
import ar from './lang/ar'  //阿拉伯语
import az from './lang/az'  //阿塞拜疆
import fr from './lang/fr'  //法语
import ko from './lang/ko'  //韩语
import pt from './lang/pt'  //葡萄牙
import ru from './lang/ru'  //俄语
import it from './lang/it' //意大利语

// // 引入英文语言包
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import enUS from 'vant/lib/locale/lang/en-US'
import esES from 'vant/lib/locale/lang/es-ES'
// import arSA from 'vant/lib/locale/lang/ar-SA'
import frFR from 'vant/lib/locale/lang/fr-FR'
import koKR from 'vant/lib/locale/lang/ko-KR'
import ptBR from 'vant/lib/locale/lang/pt-BR'
import ruRU from 'vant/lib/locale/lang/ru-RU'
import itIT from 'vant/lib/locale/lang/it-IT'

export default {
    'hy-hy': { ...zh, ...zhCN },
    'de-de': { ...de, ...deDE },
    'jp-jp': { ...jp, ...jaJP },
    'en-en': { ...en, ...enUS },
    'es-es': { ...es, ...esES },
    /*'ar-ar': {...ar,...arSA},*/
    'fr-fr': { ...fr, ...frFR },
    'ko-ko': { ...ko, ...koKR },
    'pt-pt': { ...pt, ...ptBR },
    'ru-ru': { ...ru, ...ruRU },
    'it-it': { ...it, ...itIT },
    'ar-ar': { ...ar },
    'az-az': { ...az }
}