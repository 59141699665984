module.exports = {
    xinyong: "puntuación de crédito ",
    chong_zhi_str: "Por favor, póngase en contacto con el servicio de atención al cliente en línea para recargar",
    zh_ze: "Saldo total de la cuenta",
    vstr_0_0: "Reinicio mínimo ",
    vstr_1_0: "Aplicación de optimización",
    vstr_2_0: "3 retiros por día",
    vstr_3_0: "Máximo de 3 grupos de pedidos por día",
    vstr_4_0: "Recibe un conjunto de 40/40 tareas de datos de aplicaciones",
    vstr_5_0: "Máximo de ",
    vstr_6_0: " pedidos por día",
    vstr_7_0: "Beneficio de ",
    vstr_8_0: " por aplicación",
    vstr_0_1: "Reinicio mínimo ",
    vstr_1_1: "Aplicación de optimización",
    vstr_2_1: "5 retiros por día",
    vstr_3_1: "Máximo de 4 grupos de pedidos por día",
    vstr_4_1: "Recibe un conjunto de 50/50 tareas de datos de aplicaciones",
    vstr_5_1: "Máximo de ",
    vstr_6_1: " pedidos por día",
    vstr_7_1: "Beneficio de ",
    vstr_8_1: " por aplicación",
    vstr_0_2: "Reinicio mínimo ",
    vstr_1_2: "Aplicación de optimización",
    vstr_2_2: "8 retiros por día",
    vstr_3_2: "Máximo de 4 grupos de pedidos por día",
    vstr_4_2: "Recibe un conjunto de 55/55 tareas de datos de aplicaciones",
    vstr_5_2: "Máximo de ",
    vstr_6_2: " pedidos por día",
    vstr_7_2: "Beneficio de ",
    vstr_8_2: " por aplicación",
    vstr_0_3: "Reinicio mínimo ",
    vstr_1_3: "Aplicación de optimización",
    vstr_2_3: "10 retiros por día",
    vstr_3_3: "Máximo de 5 grupos de pedidos por día",
    vstr_4_3: "Recibe un conjunto de 60/60 tareas de datos de aplicaciones",
    vstr_5_3: "Máximo de ",
    vstr_6_3: " pedidos por día",
    vstr_7_3: "Beneficio de ",
    vstr_8_3: " por aplicación",

    copy_str: "Copiar",
    hd_yj: "Obtener comisión",
    ky_ye: "Saldo disponible",
    home: 'Página principal',

    about: 'Acerca de',
    order: 'Pedido',
    record: 'Registro de pedidos',
    user: 'Usuario',
    number_x: 'cantidad',
    about_text1: 'productos de calidad',
    about_text2: 'Más productos',
    order_title: 'Tarea',
    zongzichan: 'Total de activos',
    jinridan: 'Cantidad de órdenes',
    tiyanjin: 'Fondo de experiencia',
    jinrishou: 'Ingresos de hoy',
    jintuandui: 'Comisión de equipo de hoy',
    dianjigou: 'Iniciar tarea',
    adianjigou: 'Enviar',
    order_record: 'Registro de órdenes',
    all: 'Todo',
    undone: 'No completado',
    completed: 'Completado',
    score: 'Puntuación',
    benutzer: 'Usuario',
    chongzhi: 'Recarga',
    tixian: 'Retiro',
    details: 'Detalles de la cuenta',
    password: 'Contrase?a de inicio de sesión',
    password_jy: 'Contrase?a de transacción',
    notify: 'Notificación del sistema',
    kefu: 'Servicio al cliente',
    bank_card: 'Vincular dirección',
    invite: 'Invitar amigos',
    team: 'Informe del equipo',
    language: 'Seleccionar idioma',
    member: 'Actualizar membresía',
    balance: 'Saldo de la cuenta',
    quit: 'Cerrar sesión',
    invite_text1: 'Invita amigos y gana dinero',
    invite_code: 'Código de invitación',
    invite_btn: 'Enlace de invitación: clic para copiar',
    copy_s: 'Copiado exitosamente',
    copy_b: 'Copia fallida',
    grade: 'Nivel de membresía',
    my_money: 'Saldo de mi cuenta',
    open_member: 'Abrir membresía',
    withdraw_num: 'Número de retiros',
    day: 'Día',
    withdraw_quota: 'Límite de retiro',
    order_num: 'Número de pedidos',
    profit_scale: 'Porcentaje de comisión',
    member_time: 'Membresía válida permanentemente',
    confirm_pay: 'Confirmar pago',
    order_sub: 'Enviar pedido',
    user_info: 'Información del usuario',
    avatar: 'Avatar',
    username: 'Nombre de usuario',
    set_up: 'Configurar ahora',
    revise_name: 'Modificar nombre',
    username_hint: 'Por favor, ingresa un nombre de usuario',
    user_account: 'Cuenta de usuario',
    add_money: 'Agregar fondos',
    add_money_num: 'Monto a agregar',
    add_money_hint1: '1. El monto a pagar debe ser igual al monto del pedido, de lo contrario no se acreditará automáticamente',
    add_money_hint2: '2. Si no recibe fondos agregados o retirados, consulte con su superior o servicio al cliente para resolver cualquier problema',
    add_money_hint3: 'Para tarifas, póngase en contacto con servicio al cliente',
    add_money_record: 'Registro de fondos agregados',
    withdraw_record: 'Registro de retiros',
    withdraw_money: 'Monto a retirar',
    can_withdraw_money: 'Saldo disponible para retiro',
    order_number: 'SN',
    money: 'Dinero',
    bank: 'BANCO',
    transaction_flow: 'Número de transacción',
    already_paid: 'Ya he pagado',
    upload_pay_img: 'Subir captura de pago',
    order_details: 'Detalles del pedido',
    old_password: 'Contrase?a anterior',
    new_password: 'Nueva contrase?a',
    repeat_password: 'Repetir contrase?a',
    enter: 'Ingrese',
    password_hint: 'Por favor, recuerde su contrase?a. Si olvida su contrase?a, comuníquese con servicio al cliente',
    submit: 'Enviar',
    bankCard: 'Vincular dirección',
    bank: 'Banco',
    bank_card_num: 'Número de tarjeta bancaria',
    bank_card_name: 'Nombre del titular de la tarjeta',
    team: 'Informe del equipo',
    today: 'Hoy',
    yesterday: 'Ayer',
    week: 'Esta semana',
    scale: 'Escala',
    team_people: 'Cantidad de personas en el equipo',
    team_order_scale: 'Comisión por pedidos del equipo',
    open_bank_name: 'Nombre de la cuenta bancaria',
    phone: 'Número de teléfono',
    user_password: 'Contrase?a',
    login: 'Iniciar sesión',
    register: 'Registro',
    password_qr: 'Confirmar contrase?a',
    pwd_no_same: 'Las contrase?as no coinciden',
    loading: 'Cargando',
    freeze: 'Congelar',
    pending: 'Pendiente',
    order_hao: 'Número de pedido',
    order_time: 'Hora de pedido',
    price: 'Precio',
    order_total_price: 'Total del pedido',
    level: 'Nivel de membresía',
    level_up: 'Ascenso de nivel',
    pay_way: 'Método de pago',
    money_min: 'La cantidad es demasiado peque?a',
    pay_no: 'Seleccione un método de pago',
    team_all: 'Todo',
    team_yi: 'Nivel 1',
    team_er: 'Nivel 2',
    team_san: 'Nivel 3',
    close_order: 'Cancelar pedido',
    shouru: 'Ingreso',
    zhichu: 'Gasto',
    welcome: 'Bienvenido',
    order_kong: 'El número de pedido no puede estar vacío',
    quer: 'Aceptar',
    quxiao: 'Cancelar',
    qianbaodi: 'Dirección de la billetera',
    xingming: 'Nombre',
    bank_chong: 'No se puede vincular repetidamente',
    introduce: 'Introducción de la empresa',
    platform: 'Reglas de la plataforma',
    tixianzhang: 'Cuenta de retiro',
    xuanze: 'Seleccione',
    xiayiye: 'Haga clic para cargar la página siguiente',
    certificate: 'Certificado',
    faq: 'FAQ',
    tc: 'T&C',
    guanyu_wm: 'Acerca de nosotros',
    zuixin_sj: 'últimas noticias',
    usdt_tx: 'Retiro de USDT',
    bank_tx: 'Retiro a cuenta bancaria',
    bank_tx_kf: 'Por favor, contacte al servicio al cliente en línea',
    dongjie_ye: 'Saldo congelado',
    zanwu_sj: 'Sin datos',
    //order Add1
    expected_return: 'Rendimiento esperado',
    order_step_1: 'Hoteles de calidad a juego',
    order_step_2: 'Espera...',
    order_step_3: 'Hotel confirmado',
}