module.exports = {
  xinyong: "신용 점수",
  chong_zhi_str: "충전을 위해 온라인 고객 서비스에 문의하십시오",
  zh_ze: "계좌 총 잔액",
  vstr_0_0: "최소 재설정 ",
  vstr_1_0: "최적화 앱",
  vstr_2_0: "하루에 3회 인출",
  vstr_3_0: "하루에 최대 3개의 주문 그룹",
  vstr_4_0: "40/40 앱 데이터 작업 세트 수신",
  vstr_5_0: "최대 ",
  vstr_6_0: " 주문/일",
  vstr_7_0: "1 앱 당 이익",
  vstr_8_0: " ",
  vstr_0_1: "최소 재설정 ",
  vstr_1_1: "최적화 앱",
  vstr_2_1: "하루에 5회 인출",
  vstr_3_1: "하루에 최대 4개의 주문 그룹",
  vstr_4_1: "50/50 앱 데이터 작업 세트 수신",
  vstr_5_1: "최대 ",
  vstr_6_1: " 주문/일",
  vstr_7_1: "1 앱 당 이익",
  vstr_8_1: " ",
  vstr_0_2: "최소 재설정 ",
  vstr_1_2: "최적화 앱",
  vstr_2_2: "하루에 8회 인출",
  vstr_3_2: "하루에 최대 4개의 주문 그룹",
  vstr_4_2: "55/55 앱 데이터 작업 세트 수신",
  vstr_5_2: "최대 ",
  vstr_6_2: " 주문/일",
  vstr_7_2: "1 앱 당 이익",
  vstr_8_2: " ",
  vstr_0_3: "최소 재설정 ",
  vstr_1_3: "최적화 앱",
  vstr_2_3: "하루에 10회 인출",
  vstr_3_3: "하루에 최대 5개의 주문 그룹",
  vstr_4_3: "60/60 앱 데이터 작업 세트 수신",
  vstr_5_3: "최대 ",
  vstr_6_3: " 주문/일",
  vstr_7_3: "1 앱 당 이익",
  vstr_8_3: " ",

  copy_str: "복사",
  hd_yj: "커미션 받기",
  ky_ye: "사용 가능 잔액",
  home: '홈',
  about: '정보',
  order: '주문',
  record: '기록',
  user: '사용자',
  number_x: '수량',
  about_text1: '고품질 상품',
  about_text2: '더 많은 제품',
  order_title: '작업',
  zongzichan: '총 자산',
  jinridan: '주문 수량',
  tiyanjin: '체험 자금',
  jinrishou: '금일 수입',
  jintuandui: '금일 팀 수수료',
  dianjigou: '작업 시작',
  adianjigou: '제출',
  order_record: '주문 기록',
  all: '전체',
  undone: '미완료',
  completed: '완료됨',
  score: '평점',
  benutzer: '사용자',
  chongzhi: '충전',
  tixian: '인출',
  details: '계정 세부 정보',
  password: '로그인 비밀번호',
  password_jy: '거래 비밀번호',
  notify: '시스템 알림',
  kefu: '고객 서비스',
  bank_card: '주소 바인딩',
  invite: '친구 초대',
  team: '팀 보고서',
  language: '언어 선택',
  member: '회원 등급',
  balance: '계정 잔액',
  quit: '로그아웃',
  invite_text1: '친구 초대하여 현금 획득',
  invite_code: '초대 코드',
  invite_btn: '초대 링크: 클릭하여 복사',
  copy_s: '복사 성공',
  copy_b: '복사 실패',
  grade: '회원 등급',
  my_money: '내 계정 잔액',
  open_member: '회원 가입',
  withdraw_num: '인출 횟수',
  day: '일',
  withdraw_quota: '인출 한도',
  order_num: '주문 수량',
  profit_scale: '수익 배율',
  member_time: '회원은 영구적으로 유효합니다',
  confirm_pay: '결제 확인',
  order_sub: '주문 제출',
  user_info: '사용자 정보',
  avatar: '프로필 사진',
  username: '사용자 이름',
  set_up: '즉시 설정',
  revise_name: '즉시 설정',
  username_hint: '사용자 이름 입력',
  user_account: '사용자 계정',
  add_money: '충전',
  add_money_num: '충전 금액',
  add_money_hint1: '1. ?? ??? ?? ??? ???? ??, ??? ??? ???? ???? ????',
  add_money_hint2: '2. ?? ?? ??? ???? ??? ?? ?? ???? ?? ???? ???? ?? ??? ?????',
  add_money_hint3: '??? ??? ?? ???? ?????',
  add_money_record: '입금 기록',
  withdraw_record: '출금 기록',
  withdraw_money: '출금 금액',
  can_withdraw_money: '출금 가능 금액',
  order_number: 'SN',
  money: '금액',
  bank: 'BANK',
  transaction_flow: '거래 흐름 번호',
  already_paid: '이미 지불함',
  upload_pay_img: '지불 스크린샷 업로드',
  order_details: '계정 상세내역',
  old_password: '기존 비밀번호',
  new_password: '새 비밀번호',
  repeat_password: '비밀번호 재입력',
  enter: '입력하세요',
  password_hint: '????? ????? ?? ???? ?????',
  submit: '제출',
  bankCard: '주소 바인딩',
  bank: '개설 은행',
  bank_card_num: '은행 카드 번호',
  bank_card_name: '카드 소지자 이름',
  team: '팀 보고서',
  today: '오늘',
  yesterday: '어제',
  week: '이번 주',
  scale: '비율',
  team_people: '팀 인원',
  team_order_scale: '팀 팀 주문 수수료',
  open_bank_name: '계좌명',
  phone: '휴대폰 번호',
  user_password: '비밀번호',
  login: '로그인',
  register: '가입',
  password_qr: '비밀번호 확인',
  pwd_no_same: '비밀번호 불일치',
  loading: '로딩 중',
  freeze: '동결',
  pending: '보류 중',
  order_hao: '주문 번호',
  order_time: '주문 시간',
  price: "가격",
  order_total_price: '주문 총액',
  scale: '비율',
  level: '회원 등급',
  level_up: '등급 업',
  pay_way: '입금 방법',
  money_min: '금액이 너무 적음',
  pay_no: '입금 방법을 선택하세요',
  // 新加
  team_all: '모든',
  team_yi: '일급',
  team_er: '이급',
  team_san: '삼급',
  close_order: '주문 취소',
  shouru: '수입',
  zhichu: '지출',
  welcome: '환영합니다',
  order_kong: '주문 번호를 입력하세요',
  quer: '확인',
  quxiao: '취소',
  qianbaodi: '지갑 주소',
  xingming: '이름',
  bank_chong: '중복 바인딩할 수 없음',
  introduce: '회사 소개',
  platform: '플랫폼 규정',
  tixianzhang: '출금 계좌',
  xuanze: '선택하세요',
  xiayiye: '다음 페이지 로드',
  // 新加2
  certificate: '인증서',
  faq: 'FAQ',
  tc: 'T&C',
  guanyu_wm: '회사 정보',
  zuixin_sj: '최신 소식',
  usdt_tx: 'USDT 출금',
  bank_tx: '은행 카드 출금',
  bank_tx_kf: '??? ?? ???? ?????',
  dongjie_ye: '잔액 동결',
  zanwu_sj: '데이터 없음',
  //order Add1
  expected_return: '기대수익',
  order_step_1: '품질 좋은 호텔 매칭',
  order_step_2: '대기 중...',
  order_step_3: '호텔 확인됨',
}