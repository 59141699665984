module.exports = {
  xinyong: "кредитный рейтинг",
  chong_zhi_str: "Пожалуйста, свяжитесь с онлайн-службой поддержки для пополнения",
  zh_ze: "Общий баланс счета",
  vstr_0_0: "Минимальный сброс ",
  vstr_1_0: "Оптимизация приложения",
  vstr_2_0: "3 вывода в день",
  vstr_3_0: "Максимум 3 группы заказов в день",
  vstr_4_0: "Получение набора данных для приложений 40/40",
  vstr_5_0: "Максимум ",
  vstr_6_0: " заказов в день",
  vstr_7_0: "Прибыль от ",
  vstr_8_0: " за приложение",
  vstr_0_1: "Минимальный сброс ",
  vstr_1_1: "Оптимизация приложения",
  vstr_2_1: "5 выводов в день",
  vstr_3_1: "Максимум 4 группы заказов в день",
  vstr_4_1: "Получение набора данных для приложений 50/50",
  vstr_5_1: "Максимум ",
  vstr_6_1: " заказов в день",
  vstr_7_1: "Прибыль от ",
  vstr_8_1: " за приложение",
  vstr_0_2: "Минимальный сброс ",
  vstr_1_2: "Оптимизация приложения",
  vstr_2_2: "8 выводов в день",
  vstr_3_2: "Максимум 4 группы заказов в день",
  vstr_4_2: "Получение набора данных для приложений 55/55",
  vstr_5_2: "Максимум ",
  vstr_6_2: " заказов в день",
  vstr_7_2: "Прибыль от ",
  vstr_8_2: " за приложение",
  vstr_0_3: "Минимальный сброс ",
  vstr_1_3: "Оптимизация приложения",
  vstr_2_3: "10 выводов в день",
  vstr_3_3: "Максимум 5 групп заказов в день",
  vstr_4_3: "Получение набора данных для приложений 60/60",
  vstr_5_3: "Максимум ",
  vstr_6_3: " заказов в день",
  vstr_7_3: "Прибыль от ",
  vstr_8_3: " за приложение",

  copy_str: "Копировать",
  hd_yj: "Получить комиссию",
  ky_ye: "Доступный остаток",
  home: 'Главная',
  about: 'О нас',
  order: 'Заказ',
  record: 'История заказов',
  user: 'Мой',
  number_x: 'Kоличество',
  about_text1: 'качественные товары',
  about_text2: 'Другие продукты',
  order_title: 'Задача',
  zongzichan: 'Общий капитал',
  jinridan: 'Количество заказов',
  tiyanjin: 'Опытный фонд',
  jinrishou: 'Сегодняшний доход',
  jintuandui: 'Сегодняшние командные комиссионные',
  dianjigou: 'Начать задачу',
  adianjigou: 'Отправить',
  order_record: 'Запись о заказе',
  all: 'Все',
  undone: 'Не завершено',
  completed: 'Завершено',
  score: 'Оценка',
  benutzer: 'Пользователь',
  chongzhi: 'Пополнение',
  tixian: 'Снятие',
  details: 'Детали счета',
  password: 'Пароль для входа',
  password_jy: 'Пароль для сделок',
  notify: 'Уведомление системы',
  kefu: 'Служба поддержки',
  bank_card: 'Привязать адрес',
  invite: 'Пригласить друзей',
  team: 'Отчет о команде',
  language: 'Выбор языка',
  member: 'Повышение уровня членства',
  balance: 'Баланс счета',
  quit: 'Выйти из системы',
  invite_text1: 'Приглашайте друзей и зарабатывайте наличные',
  invite_code: 'Код приглашения',
  invite_btn: 'Ссылка для приглашения: нажмите для копирования',
  copy_s: 'Скопировано успешно',
  copy_b: 'Не удалось скопировать',
  grade: 'Уровень членства',
  my_money: 'Баланс моего счета',
  open_member: 'Открыть членство',
  withdraw_num: 'Количество выводов средств',
  day: 'День',
  withdraw_quota: 'Лимит на вывод',
  order_num: 'Количество заказов',
  profit_scale: 'Процент комиссионных',
  member_time: 'Членство действует постоянно',
  confirm_pay: 'Подтвердить оплату',
  order_sub: 'Отправить заказ',
  user_info: 'Информация о пользователе',
  avatar: 'Аватар',
  username: 'Имя пользователя',
  set_up: 'Настройка сейчас',
  revise_name: 'Изменить имя',
  username_hint: 'Пожалуйста, укажите имя пользователя',
  user_account: 'Учетная запись пользователя',
  add_money: 'Пополнить счет',
  add_money_num: 'Сумма пополнения',
  add_money_hint1: '1. Сумма оплаты должна соответствовать сумме заказа, иначе деньги не будут автоматически перечислены',
  add_money_hint2: '2. Если пополнение или вывод не произошли, обратитесь к своему руководителю или службе поддержки для решения других вопросов',
  add_money_hint3: 'За платную помощь обращайтесь в службу поддержки',
  add_money_record: 'История пополнений',
  withdraw_record: 'История выводов',
  withdraw_money: 'Сумма для вывода',
  can_withdraw_money: 'Доступная для вывода сумма',
  order_number: '№ заказа',
  money: 'Деньги',
  bank: 'Банк',
  transaction_flow: 'Номер транзакции',
  already_paid: 'Я уже оплатил',
  upload_pay_img: 'Загрузить скриншот оплаты',
  order_details: 'Детали счета',
  old_password: 'Старый пароль',
  new_password: 'Новый пароль',
  repeat_password: 'Повторите пароль',
  enter: 'Введите',
  password_hint: 'Пожалуйста, запомните пароль. Если забыли, обратитесь в службу поддержки',
  submit: 'Отправить',
  bankCard: 'Привязать адрес',
  bank: 'Банк',
  bank_card_num: 'Номер банковской карты',
  bank_card_name: 'Имя держателя карты',
  team: 'Отчет о команде',
  today: 'Сегодня',
  yesterday: 'Вчера',
  week: 'На этой неделе',
  scale: 'Масштаб',
  team_people: 'Количество людей в команде',
  team_order_scale: 'Комиссия за заказы команды',
  open_bank_name: 'Название открытого банка',
  phone: 'Телефон',
  user_password: 'Пароль',
  login: 'Войти',
  register: 'Зарегистрироваться',
  password_qr: 'Подтвердите пароль',
  pwd_no_same: 'Пароли не совпадают',
  loading: 'Загрузка...',
  freeze: 'Заморозить',
  pending: 'В ожидании',
  order_hao: 'Номер заказа',
  order_time: 'Время заказа',
  price: "Цена",
  order_total_price: 'Общая сумма заказа',
  scale: 'Комиссия',
  level: 'Уровень участника',
  level_up: 'Повышение уровня',
  pay_way: 'Способ оплаты',
  money_min: 'Сумма слишком мала',
  pay_no: 'Пожалуйста, выберите способ оплаты',
  // Новые добавления
  team_all: 'Все',
  team_yi: 'Первый уровень',
  team_er: 'Второй уровень',
  team_san: 'Третий уровень',
  close_order: 'Закрыть заказ',
  shouru: 'Доход',
  zhichu: 'Расход',
  welcome: 'Добро пожаловать',
  order_kong: 'Номер заказа не может быть пустым',
  quer: 'Подтвердить',
  quxiao: 'Отмена',
  qianbaodi: 'Адрес кошелька',
  xingming: 'Имя',
  bank_chong: 'Нельзя повторно привязать',
  introduce: 'О компании',
  platform: 'Правила платформы',
  tixianzhang: 'Счет для вывода средств',
  xuanze: 'Пожалуйста, выберите',
  xiayiye: 'Нажмите, чтобы загрузить следующую страницу',
  // Дополнительные нововведения
  certificate: 'Сертификат',
  faq: 'FAQ',
  tc: 'T&C',
  guanyu_wm: 'О нас',
  zuixin_sj: 'Последние новости',
  usdt_tx: 'Вывод USDT',
  bank_tx: 'Вывод на банковскую карту',
  bank_tx_kf: 'Пожалуйста, свяжитесь с онлайн-службой поддержки',
  dongjie_ye: 'Замороженный баланс',
  zanwu_sj: 'Нет данных',
  //order Add1
  expected_return: 'Ожидаемый доход',
  order_step_1: 'Соответствие качества отелей',
  order_step_2: 'Ожидающий...',
  order_step_3: 'Отель подтвержден',
}