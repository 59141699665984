module.exports = {
    xinyong: "Punteggio di credito",
    chong_zhi_str: "Si prega di contattare il servizio clienti online per la ricarica",
    zh_ze: "Saldo totale del conto",
    vstr_0_0: "Ripristino minimo",
    vstr_1_0: "Ottimizzazione App",
    vstr_2_0: "3 prelievi al giorno",
    vstr_3_0: "Massimo di 3 gruppi di ordini al giorno",
    vstr_4_0: "Ricevi un set di 40/40 compiti di dati delle app",
    vstr_5_0: "Massimo",
    vstr_6_0: " ordini al giorno",
    vstr_7_0: "Profitto di",
    vstr_8_0: " per applicazione",
    vstr_0_1: "Ripristino minimo",
    vstr_1_1: "Ottimizzazione App",
    vstr_2_1: "5 prelievi al giorno",
    vstr_3_1: "Massimo di 4 gruppi di ordini al giorno",
    vstr_4_1: "Ricevi un set di 50/50 compiti di dati delle app",
    vstr_5_1: "Massimo",
    vstr_6_1: " ordini al giorno",
    vstr_7_1: "Profitto di",
    vstr_8_1: " per applicazione",
    vstr_0_2: "Ripristino minimo",
    vstr_1_2: "Ottimizzazione App",
    vstr_2_2: "8 prelievi al giorno",
    vstr_3_2: "Massimo di 4 gruppi di ordini al giorno",
    vstr_4_2: "Ricevi un set di 55/55 compiti di dati delle app",
    vstr_5_2: "Massimo",
    vstr_6_2: " ordini al giorno",
    vstr_7_2: "Profitto di",
    vstr_8_2: " per applicazione",
    vstr_0_3: "Ripristino minimo",
    vstr_1_3: "Ottimizzazione App",
    vstr_2_3: "10 prelievi al giorno",
    vstr_3_3: "Massimo di 5 gruppi di ordini al giorno",
    vstr_4_3: "Ricevi un set di 60/60 compiti di dati delle app",
    vstr_5_3: "Massimo",
    vstr_6_3: " ordini al giorno",
    vstr_7_3: "Profitto di",
    vstr_8_3: " per applicazione",
    copy_stredit: "Modifica questo e contatta il servizio",
    copy_str: "Copia",
    hd_yj: "Guadagna commissione",
    ky_ye: "Saldo disponibile",
    banner1_text: 'La vita è troppo breve per passare il tempo con persone che ti tolgono la felicità. Se qualcuno ti vuole nella sua vita, farà spazio per te.',
    leistungsumfang: 'Ambito di servizio',
    leistungsumfang_text: 'Attualmente serviamo oltre 100.000 clienti e riceviamo buone recensioni',
    leistungsumfang_free: 'TRASFERIMENTO GRATUITO',
    home: 'Home',
    about: 'Informazioni',
    order: 'Ordine',
    record: 'Registro ordini',
    user: 'Il mio',
    number_x: 'Quantità',

    about_text1: 'Prodotti di qualità',
    about_text2: 'Più prodotti',
    order_title: 'Compito',
    zongzichan: 'Attività totali',
    jinridan: 'Quantità di ordini',
    tiyanjin: 'Fondi di prova',
    jinrishou: `Commissione di oggi`,
    jintuandui: `Commissione del team di oggi`,
    dianjigou: 'Avvia compito',
    adianjigou: 'Invia',
    order_record: 'Registri degli ordini',
    all: 'Tutti',
    undone: 'Incompleti',
    completed: 'Completati',
    score: 'Punteggio',
    benutzer: 'Utente',
    chongzhi: 'Deposito',
    tixian: 'Prelievo',
    details: 'Dettagli del conto',
    password: 'Password di accesso',
    password_jy: 'Password di transazione',
    notify: 'Notifica di sistema',
    kefu: 'Servizio clienti',
    bank_card: 'Indirizzo di collegamento',
    invite: 'Invita amici',
    team: 'Rapporto di squadra',
    language: 'Seleziona lingua',
    member: 'Upgrade Membership',
    balance: 'Saldo del conto',
    quit: 'Logout',
    invite_text1: 'Invita amici per guadagnare denaro',
    invite_code: 'Codice di invito',
    invite_btn: 'Link di invito: Clicca per copiare',
    copy_s: 'Copia avvenuta con successo',
    copy_b: 'Copia fallita',
    grade: 'Livello di appartenenza',
    my_money: 'Il mio saldo del conto',
    open_member: 'Apri membership',
    withdraw_num: 'Numero di prelievi',
    day: 'Giorno',
    withdraw_quota: 'Limite di prelievo',
    order_num: 'Numero di ordini ricevuti',
    profit_scale: 'Tasso di commissione',
    member_time: 'Membresia valida permanentemente',
    confirm_pay: 'Conferma pagamento',
    order_sub: 'Invia ordine',
    user_info: 'Informazioni utente',
    avatar: 'Avatar',
    username: 'Nome utente',
    set_up: 'Imposta ora',
    revise_name: 'Modifica nome',
    username_hint: 'Inserisci il nome utente',
    user_account: 'Conto utente',
    add_money: 'Aggiungi denaro',
    add_money_num: 'Importo da ricaricare',
    add_money_hint1: '1. L\'importo del pagamento deve corrispondere all\'importo dell\'ordine, altrimenti non verrà accreditato automaticamente.',
    add_money_hint2: '2. Se la ricarica o il prelievo non vengono ricevuti, si prega di consultare il proprio superiore o il servizio clienti per la risoluzione.',
    add_money_hint3: 'Contatta il servizio clienti per le commissioni.',
    add_money_record: 'Registri di ricarica',
    withdraw_record: 'Registri di prelievo',
    withdraw_money: 'Importo di prelievo',
    can_withdraw_money: 'Importo prelevabile disponibile',
    order_number: 'Numero ordine',
    money: 'Importo',
    bank: 'BANCA',
    transaction_flow: 'ID transazione',
    already_paid: 'Ho pagato',
    upload_pay_img: 'Carica screenshot del pagamento',
    order_details: 'Dettagli dell\'account',
    old_password: 'Vecchia password',
    new_password: 'Nuova password',
    repeat_password: 'Ripeti password',
    enter: 'Si prega di inserire',
    password_hint: 'Si prega di ricordare la password. Se la dimenticate, contattate il servizio clienti.',
    submit: 'Invia',
    bankCard: 'Indirizzo di collegamento',
    bank: 'Banca',
    bank_card_num: 'Numero di carta bancaria',
    bank_card_name: 'Nome del titolare della carta',
    team: 'Rapporto di squadra',
    today: 'Oggi',
    yesterday: 'Ieri',
    week: 'Questa settimana',
    scale: 'Profitto',
    team_people: 'Dimensione del team',
    team_order_scale: 'Commissione sugli ordini del team',
    open_bank_name: 'Nome del titolare del conto',
    phone: 'Numero di telefono',
    user_password: 'Password',
    login: 'Accedi',
    register: 'Registrati',
    password_qr: 'Conferma password',
    pwd_no_same: 'Le password non corrispondono',
    loading: 'Caricamento',
    freeze: 'Congela',
    pending: 'Importo in sospeso',
    order_hao: 'Numero ordine',
    order_time: 'Ora dell\'ordine',
    price: 'Prezzo unitario',
    order_total_price: 'Importo totale dell\'ordine',
    level: 'Livello di appartenenza',
    level_up: 'Aggiorna membership',
    pay_way: 'Metodo di pagamento',
    money_min: 'Importo troppo piccolo',
    pay_no: 'Seleziona il metodo di pagamento',
    team_all: 'Tutti',
    team_yi: 'Livello uno',
    team_er: 'Livello due',
    team_san: 'Livello tre',
    close_order: 'Annulla ordine',
    shouru: 'Entrate',
    zhichu: 'Spese',
    welcome: 'Benvenuto',
    order_kong: 'Il numero dell\'ordine non può essere vuoto',
    quer: 'Conferma',
    quxiao: 'Annulla',
    qianbaodi: 'Indirizzo del portafoglio',
    xingming: 'Nome',
    bank_chong: 'Impossibile collegare ripetutamente',
    introduce: 'Introduzione aziendale',
    platform: 'Regole della piattaforma',
    tixianzhang: 'Conto di prelievo',
    xuanze: 'Si prega di selezionare',
    xiayiye: 'Clicca per caricare la pagina successiva',
    certificate: 'Certificato',
    faq: 'FAQ',
    tc: 'T&C',
    guanyu_wm: 'Chi siamo',
    zuixin_sj: 'Ultimi eventi',
    usdt_tx: 'Prelievo USDT',
    bank_tx: 'Prelievo su carta bancaria',
    bank_tx_kf: 'Si prega di contattare il servizio clienti online',
    dongjie_ye: 'Saldo congelato',
    zanwu_sj: 'Nessun dato',
    expected_return: 'Rendimento previsto',
    order_step_1: 'Abbinamento di hotel di qualità',
    order_step_2: 'Attesa...',
    order_step_3: 'Hotel confermato',

}