module.exports = {
  xinyong: "信用分",
  chong_zhi_str: "请联系在线客服充值",
  zh_ze: "账户总余额",
  vstr_0_0: "最小重置 ",
  vstr_1_0: "优化应用",
  vstr_2_0: "每天3次提款",
  vstr_3_0: "每天最多3组订单",
  vstr_4_0: "收到一组40/40个应用数据任务",
  vstr_5_0: "每天最多 ",
  vstr_6_0: "笔订单",
  vstr_7_0: "每个应用利润 ",
  vstr_8_0: "每个应用",
  vstr_0_1: "最小重置 ",
  vstr_1_1: "优化应用",
  vstr_2_1: "每天5次提款",
  vstr_3_1: "每天最多4组订单",
  vstr_4_1: "收到一组50/50个应用数据任务",
  vstr_5_1: "每天最多 ",
  vstr_6_1: "笔订单",
  vstr_7_1: "每个应用利润 ",
  vstr_8_1: "每个应用",
  vstr_0_2: "最小重置 ",
  vstr_1_2: "优化应用",
  vstr_2_2: "每天8次提款",
  vstr_3_2: "每天最多4组订单",
  vstr_4_2: "收到一组55/55个应用数据任务",
  vstr_5_2: "每天最多 ",
  vstr_6_2: "笔订单",
  vstr_7_2: "每个应用利润 ",
  vstr_8_2: "每个应用",
  vstr_0_3: "最小重置 ",
  vstr_1_3: "优化应用",
  vstr_2_3: "每天10次提款",
  vstr_3_3: "每天最多5组订单",
  vstr_4_3: "收到一组60/60个应用数据任务",
  vstr_5_3: "每天最多 ",
  vstr_6_3: "笔订单",
  vstr_7_3: "每个应用利润 ",
  vstr_8_3: "每个应用",




  copy_stredit: "修改地址联系在线客",
  copy_str: "复制",
  hd_yj: "获得佣金",
  ky_ye: "可用余额",
  banner1_text: 'Life is too short to spend time with people who suck the happiness out of you. If someone wants you in their life, they’ll make room for you.',
  leistungsumfang: 'Leistungsumfang',
  leistungsumfang_text: 'Currently serving 100.000+ custormers and receiving good reviews',
  leistungsumfang_free: 'FREE TRANSFER',
  home: '主页',
  about: '关于',
  order: '订单',
  record: '订单记录',
  user: '我的',
  number_x: '数量',
  about_text1: 'quality goods',
  about_text2: 'More products',
  order_title: '任务',
  zongzichan: '总资产',
  jinridan: '订单数量',
  tiyanjin: '体验基金',
  jinrishou: '今日佣金',
  jintuandui: '今日团队佣金',
  dianjigou: '开始任务',
  adianjigou: '提交',
  order_illustrate: 'The platform is not responsible if money is incorrectly transferred to your bank account.The minimum withdrawal amount is 50,000 won.',
  order_record: '订单记录',
  all: '全部',
  undone: '未完成',
  completed: '已完成',
  score: '评分',
  benutzer: '用户',
  chongzhi: '存款',
  tixian: '提现',
  details: '账户明细',
  password: '登录密码',
  password_jy: '交易密码',
  notify: '系统通知',
  kefu: '客服',
  bank_card: '绑定地址',
  invite: '邀请好友',
  team: '团队报告',
  language: '选择语言',
  member: '会员升级',
  balance: '账户余额',
  quit: '退出登录',
  invite_text1: '邀好友赚现金',
  invite_code: '邀请码',
  invite_btn: '邀请链接：点击复制',
  copy_s: '复制成功',
  copy_b: '复制失败',
  grade: '会员等级',
  my_money: '我的账户余额',
  open_member: '开通会员',
  withdraw_num: '提现次数',
  day: '天',
  withdraw_quota: '提现额度',
  order_num: '接单数量',
  profit_scale: '佣金比例',
  member_time: '会员永久有效',
  confirm_pay: '确认支付',
  order_sub: '提交订单',
  user_info: '用户信息',
  avatar: '头像',
  username: '用户名',
  set_up: '立即设置',
  revise_name: '修改名称',
  username_hint: '请填写用户名',
  user_account: '用户账号',
  add_money: '充值',
  add_money_num: '充值金额',
  add_money_hint1: '1。付款金额必须与订单金额一致,否则不会自动到达',
  add_money_hint2: '2.如果未收到充值和提现,请咨询您的上级或客服以解决其他问题',
  add_money_hint3: '收费请联系客服',
  add_money_record: '充值记录',
  withdraw_record: '提现记录',
  withdraw_money: '提现金额',
  can_withdraw_money: '可提现金额',
  order_number: 'SN',
  money: '金额',
  bank: 'BANK',
  transaction_flow: '交易流水号',
  already_paid: '我已付款',
  upload_pay_img: '上传付款截图',
  order_details: '账户明细',
  old_password: '旧密码',
  new_password: '新密码',
  repeat_password: '重复密码',
  enter: '请输入',
  password_hint: '请牢记密码，如忘记密码请联系客服',
  submit: '提交',
  bankCard: '绑定地址',
  bank: '开户行',
  bank_card_num: '银行卡号',
  bank_card_name: '持卡人姓名',
  team: '团队报表',
  today: '今天',
  yesterday: '昨天',
  week: '本周',
  scale: '比例',
  team_people: '团队人数',
  team_order_scale: '团队订单佣金',
  open_bank_name: '开户名',
  phone: '手机号',
  user_password: '密码',
  login: '登录',
  register: '注册',
  password_qr: '确认密码',
  pwd_no_same: '密码不一致',
  loading: '加载中',
  freeze: '冻结',
  pending: '待处理金额',
  order_hao: '订单号',
  order_time: '抢单时间',
  price: "单价",
  order_total_price: '订单总额',
  scale: '佣金',
  level: '会员等级',
  level_up: '会员升级',
  pay_way: '充值方式',
  money_min: '金额太小',
  pay_no: '请选择充值方式',
  // 新加
  team_all: '全部',
  team_yi: '一级',
  team_er: '二级',
  team_san: '三级',
  close_order: '取消订单',
  shouru: '收入',
  zhichu: '支出',
  welcome: 'Welcome',
  order_kong: '订单号不能为空',
  quer: '确认',
  quxiao: '取消',
  qianbaodi: '钱包地址',
  xingming: '姓名',
  bank_chong: '不能重复绑定',
  introduce: '公司介绍',
  platform: '平台规则',
  tixianzhang: '提现账户',
  xuanze: '请选择',
  xiayiye: '点击加载下一页',
  // 新加2
  certificate: '证书',
  faq: 'FAQ',
  tc: 'T&C',
  guanyu_wm: '关于我们',
  zuixin_sj: '最新事件',
  usdt_tx: 'USDT提现',
  bank_tx: '银行卡提现',
  bank_tx_kf: '请联系在线客服',
  dongjie_ye: '冻结余额',
  zanwu_sj: '暂无数据',
  //order Add1
  expected_return: '预期回报',
  order_step_1: '匹配优质酒店',
  order_step_2: '等待...',
  order_step_3: '酒店已确认',
}