module.exports = {
  xinyong: "pontuação de crédito",
  chong_zhi_str: "Por favor, entre em contato com o serviço de atendimento ao cliente online para recarregar",
  zh_ze: "Saldo total da conta",
  vstr_0_0: "Redefinição mínima ",
  vstr_1_0: "Aplicativo de Otimização",
  vstr_2_0: "3 retiradas por dia",
  vstr_3_0: "Máximo de 3 grupos de pedidos por dia",
  vstr_4_0: "Receba um conjunto de 40/40 tarefas de dados de aplicativos",
  vstr_5_0: "Máximo de ",
  vstr_6_0: " pedidos por dia",
  vstr_7_0: "Lucro de ",
  vstr_8_0: " por aplicativo",
  vstr_0_1: "Redefinição mínima ",
  vstr_1_1: "Aplicativo de Otimização",
  vstr_2_1: "5 retiradas por dia",
  vstr_3_1: "Máximo de 4 grupos de pedidos por dia",
  vstr_4_1: "Receba um conjunto de 50/50 tarefas de dados de aplicativos",
  vstr_5_1: "Máximo de ",
  vstr_6_1: " pedidos por dia",
  vstr_7_1: "Lucro de ",
  vstr_8_1: " por aplicativo",
  vstr_0_2: "Redefinição mínima ",
  vstr_1_2: "Aplicativo de Otimização",
  vstr_2_2: "8 retiradas por dia",
  vstr_3_2: "Máximo de 4 grupos de pedidos por dia",
  vstr_4_2: "Receba um conjunto de 55/55 tarefas de dados de aplicativos",
  vstr_5_2: "Máximo de ",
  vstr_6_2: " pedidos por dia",
  vstr_7_2: "Lucro de ",
  vstr_8_2: " por aplicativo",
  vstr_0_3: "Redefinição mínima ",
  vstr_1_3: "Aplicativo de Otimização",
  vstr_2_3: "10 retiradas por dia",
  vstr_3_3: "Máximo de 5 grupos de pedidos por dia",
  vstr_4_3: "Receba um conjunto de 60/60 tarefas de dados de aplicativos",
  vstr_5_3: "Máximo de ",
  vstr_6_3: " pedidos por dia",
  vstr_7_3: "Lucro de ",
  vstr_8_3: " por aplicativo",

  copy_str: "Copiar",
  hd_yj: "Ganhar comissão",
  ky_ye: "Saldo disponível",
  home: 'Página Inicial',
  about: 'Sobre',
  order: 'Pedido',
  record: 'Registro de Pedidos',
  user: 'Minha Conta',
  number_x: 'Quantidade',
  about_text1: 'produtos de qualidade',
  about_text2: 'Mais produtos',
  order_title: 'Tarefa',
  zongzichan: 'Ativo Total',
  jinridan: 'Quantidade de Pedidos',
  tiyanjin: 'Fundo de Experiência',
  jinrishou: 'Receita de Hoje',
  jintuandui: 'Comiss?o da Equipe de Hoje',
  dianjigou: 'Iniciar Tarefa',
  adianjigou: 'Enviar',
  order_record: 'Registro de Pedidos',
  all: 'Todos',
  undone: 'N?o Concluído',
  completed: 'Concluído',
  score: 'Pontua??o',
  benutzer: 'Usuário',
  chongzhi: 'Recarregar',
  tixian: 'Retirar',
  details: 'Detalhes da Conta',
  password: 'Senha de Login',
  password_jy: 'Senha de Transa??o',
  notify: 'Notifica??o do Sistema',
  kefu: 'Atendimento ao Cliente',
  bank_card: 'Vincular endereço',
  invite: 'Convidar Amigos',
  team: 'Relatório da Equipe',
  language: 'Selecionar Idioma',
  member: 'Atualiza??o de Membro',
  balance: 'Saldo da Conta',
  quit: 'Sair',
  invite_text1: 'Convide Amigos para Ganhar Dinheiro',
  invite_code: 'Código de Convite',
  invite_btn: 'Link de Convite: Clique para Copiar',
  copy_s: 'Cópia Bem-sucedida',
  copy_b: 'Cópia Mal-sucedida',
  grade: 'Nível de Membro',
  my_money: 'Meu Saldo na Conta',
  open_member: 'Tornar-se Membro',
  withdraw_num: 'Número de Retiradas',
  day: 'Dia',
  withdraw_quota: 'Limite de Retirada',
  order_num: 'Número de Pedidos',
  profit_scale: 'Taxa de Comiss?o',
  member_time: 'Membro Válido Permanentemente',
  confirm_pay: 'Confirmar Pagamento',
  order_sub: 'Submeter Pedido',
  user_info: 'Informa??es do Usuário',
  avatar: 'Avatar',
  username: 'Nome de Usuário',
  set_up: 'Configurar Agora',
  revise_name: 'Editar Nome',
  username_hint: 'Por favor, preencha o nome de usuário',
  user_account: 'Conta do Usuário',
  add_money: 'Adicionar Dinheiro',
  add_money_num: 'Valor a Adicionar',
  add_money_hint1: '1. O valor do pagamento deve corresponder ao valor do pedido; caso contrário, n?o será processado automaticamente',
  add_money_hint2: '2. Se você n?o receber o pagamento ou a retirada, consulte seu superior ou o suporte ao cliente para resolver outros problemas',
  add_money_hint3: 'Para taxas, entre em contato com o suporte ao cliente',
  add_money_record: 'Registro de Adi??o de Dinheiro',
  withdraw_record: 'Registro de Retirada',
  withdraw_money: 'Valor a Retirar',
  can_withdraw_money: 'Valor Disponível para Retirada',
  order_number: 'No do Pedido',
  money: 'Dinheiro',
  bank: 'BANCO',
  transaction_flow: 'Número de Transa??o',
  already_paid: 'Já Paguei',
  upload_pay_img: 'Enviar Captura de Tela do Pagamento',
  order_details: 'Detalhes do Pedido',
  old_password: 'Senha Antiga',
  new_password: 'Nova Senha',
  repeat_password: 'Repetir Senha',
  enter: 'Por Favor, Digite',
  password_hint: 'Por favor, lembre-se da senha; se esquecê-la, entre em contato com o suporte ao cliente',
  submit: 'Enviar',
  bankCard: 'Vincular endereço',
  bank: 'Banco',
  bank_card_num: 'Número do Cart?o Bancário',
  bank_card_name: 'Nome do Titular do Cart?o',
  team: 'Relatório da Equipe',
  today: 'Hoje',
  yesterday: 'Ontem',
  week: 'Esta Semana',
  scale: 'Escala',
  team_people: 'Número de Pessoas na Equipe',
  team_order_scale: 'Comiss?o de Pedidos da Equipe',
  open_bank_name: 'Nome do Banco',
  phone: 'Número de Telefone',
  user_password: 'Senha',
  login: 'Entrar',
  register: 'Registrar',
  password_qr: 'Confirma??o de Senha',
  pwd_no_same: 'Senhas Diferentes',
  loading: 'Carregando',
  freeze: 'Congelado',
  pending: 'Pendente',
  order_hao: 'Número do Pedido',
  order_time: 'Tempo de Pedido',
  price: 'Pre?o',
  order_total_price: 'Pre?o Total do Pedido',
  scale: 'Comiss?o',
  level: 'Nível de Membro',
  level_up: 'Atualiza??o de Nível',
  pay_way: 'Método de Pagamento',
  money_min: 'Quantia Mínima',
  pay_no: 'Por favor, selecione o método de pagamento',
  team_all: 'Todos',
  team_yi: 'Nível 1',
  team_er: 'Nível 2',
  team_san: 'Nível 3',
  close_order: 'Cancelar Pedido',
  shouru: 'Receita',
  zhichu: 'Despesa',
  welcome: 'Bem-vindo',
  order_kong: 'Número do Pedido n?o pode estar vazio',
  quer: 'Confirmar',
  quxiao: 'Cancelar',
  qianbaodi: 'Endere?o da Carteira',
  xingming: 'Nome',
  bank_chong: 'N?o é possível vincular repetidamente',
  introduce: 'Apresenta??o da Empresa',
  platform: 'Regras da Plataforma',
  tixianzhang: 'Conta de Retirada',
  xuanze: 'Por favor, selecione',
  xiayiye: 'Clique para carregar a próxima página',
  certificate: 'Certificado',
  faq: 'FAQ',
  tc: 'T&C',
  guanyu_wm: 'Sobre Nós',
  zuixin_sj: 'últimas Notícias',
  usdt_tx: 'Retirada de USDT',
  bank_tx: 'Retirada para Conta Bancária',
  bank_tx_kf: 'Por favor, entre em contato com o suporte online',
  dongjie_ye: 'Saldo Congelado',
  zanwu_sj: 'Sin datos',
  //order Add1
  expected_return: 'Retorno esperado',
  order_step_1: 'Hotéis de qualidade correspondente',
  order_step_2: 'Espera...',
  order_step_3: 'Hotel confirmado',
}