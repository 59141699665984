module.exports = {
  xinyong: "クレジットスコア",
  chong_zhi_str: "再充電のためにオンラインカスタマーサービスにお問い合わせください",
  zh_ze: "アカウントの総残高",
  vstr_0_0: "最小リセット ",
  vstr_1_0: "最適化アプリ",
  vstr_2_0: "1日に3回の引き出し",
  vstr_3_0: "1日に最大3つの注文グループ",
  vstr_4_0: "40/40アプリデータタスクのセットを受け取る",
  vstr_5_0: "最大 ",
  vstr_6_0: " 注文/日",
  vstr_7_0: "1アプリケーションあたりの利益",
  vstr_8_0: " ",
  vstr_0_1: "最小リセット ",
  vstr_1_1: "最適化アプリ",
  vstr_2_1: "1日に5回の引き出し",
  vstr_3_1: "1日に最大4つの注文グループ",
  vstr_4_1: "50/50アプリデータタスクのセットを受け取る",
  vstr_5_1: "最大 ",
  vstr_6_1: " 注文/日",
  vstr_7_1: "1アプリケーションあたりの利益",
  vstr_8_1: " ",
  vstr_0_2: "最小リセット ",
  vstr_1_2: "最適化アプリ",
  vstr_2_2: "1日に8回の引き出し",
  vstr_3_2: "1日に最大4つの注文グループ",
  vstr_4_2: "55/55アプリデータタスクのセットを受け取る",
  vstr_5_2: "最大 ",
  vstr_6_2: " 注文/日",
  vstr_7_2: "1アプリケーションあたりの利益",
  vstr_8_2: " ",
  vstr_0_3: "最小リセット ",
  vstr_1_3: "最適化アプリ",
  vstr_2_3: "1日に10回の引き出し",
  vstr_3_3: "1日に最大5つの注文グループ",
  vstr_4_3: "60/60アプリデータタスクのセットを受け取る",
  vstr_5_3: "最大 ",
  vstr_6_3: " 注文/日",
  vstr_7_3: "1アプリケーションあたりの利益",
  vstr_8_3: " ",

  copy_str: "コピー",
  hd_yj: "手数料を得る",
  ky_ye: "利用可能な残高",
  banner1_text: 'あなたから幸せを吸い取る人々と一緒に時間を過ごすには人生は短すぎます。 誰かがあなたを人生に望んでいるなら、彼らはあなたのために場所を空けてくれるでしょう。',
  leistungsumfang: 'ライトゥングスムファン',
  leistungsumfang_text: '現在10万人以上の顧客にサービスを提供しており、良い評価を受けています',
  leistungsumfang_free: '無料送迎',
  home: 'ホーム',
  about: 'について',
  order: '完成',
  record: '完成履歴',
  user: 'マイページ',
  number_x: '量',
  about_text1: '品質の良い商品',
  about_text2: 'さらに多くの製品',
  order_title: '本日の任務',
  zongzichan: '残高',
  jinridan: '本日の任務数',
  tiyanjin: '体験基金',
  jinrishou: '本日の収入',
  jintuandui: '本日のチームバックマージン',
  dianjigou: 'スタート',
  adianjigou: '提出する',
  order_illustrate: '資金が誤って銀行口座に送金された場合、プラットフォームは一切の責任を負いません。.',
  order_record: '完成履歴',
  all: '全て',
  undone: '元に戻した',
  completed: '完了',
  score: 'スコア',
  benutzer: 'ユーザー',
  chongzhi: '補充する',
  tixian: '出金',
  details: 'アカウント詳細',
  password: 'パスワード管理',
  password_jy: 'パスワード',
  notify: 'お知らせ',
  kefu: 'カウンターサービス',
  bank_card: 'アドレスをバインド',
  invite: '友達を招待',
  team: 'チームレポート',
  language: '言語を選択してください',
  member: 'メンバーのアップグレード',
  balance: 'マイページ残高',
  quit: 'ログアウト',
  invite_text1: '友達を招待して現金を獲得しましょう',
  invite_code: '招待コード',
  invite_btn: '招待リンク：クリックしてコピー',
  copy_s: '正常にコピーされました',
  copy_b: 'コピーに失敗しました',
  grade: 'VIP等級',
  my_money: '私の口座残高',
  open_member: 'メンバーシップに参加する',
  withdraw_num: '出金回数',
  day: '空',
  withdraw_quota: '出金限度額',
  order_num: '完成数量',
  profit_scale: 'バックマージン率',
  member_time: '会員資格は永久に有効です',
  confirm_pay: '支払いを確認する',
  order_sub: '完成を送信',
  user_info: 'ユーザー情報',
  avatar: 'アバター',
  username: 'ユーザー名',
  set_up: '今すぐセットアップ',
  revise_name: 'お名前の変更',
  username_hint: 'ユーザー名を入力してください',
  user_account: 'ユーザーアカウント',
  add_money: '補充する',
  add_money_num: 'チャージ金額',
  add_money_hint1: '1.お支払い金額は完成金額と一致している必要があります。そうでない場合は自動的に届きません。',
  add_money_hint2: '2.入金・出金ができない場合,その他の問題を解決するには、上司または顧客サービスに相談してください。',
  add_money_hint3: '料金についてはカスタマーサービスにお問い合わせください',
  add_money_record: 'チャージ履歴',
  withdraw_record: '出金履歴',
  withdraw_money: '出金金額',
  can_withdraw_money: '利用可能な現金の量',
  order_number: 'SN',
  money: '額',
  bank: 'BANK',
  transaction_flow: 'トランザクションシリアル番号',
  already_paid: '私は（お金を）払いました',
  upload_pay_img: '支払いのスクリーンショットをアップロードする',
  order_details: '資金明細',
  old_password: '以前のパスワード',
  new_password: '新しいパスワード',
  repeat_password: 'パスワードを再度入力してください。',
  enter: '入ってください',
  password_hint: 'パスワードを忘れないように注意してください。パスワードを忘れた場合は、カスタマーサービスまでご連絡ください。',
  submit: '提出する',
  bankCard: '銀行口座の追加',
  bank: 'アドレスをバインド',
  bank_card_num: '銀行口座',
  bank_card_name: 'カード所有者のお名前',
  team: 'チームレポート',
  today: '今日',
  yesterday: '昨日',
  week: '今週',
  scale: '割合',
  team_people: 'チームの規模',
  team_order_scale: 'チームオーダーコミッション',
  open_bank_name: 'アカウント名',
  phone: '電話番号',
  user_password: 'パスワード',
  login: 'ログイン',
  register: '登録する',
  password_qr: 'パスワードを認証する',
  pwd_no_same: 'パスワードが一致しません',
  loading: '読み込み中',
  freeze: 'ロック',
  pending: '処理中',
  order_hao: '完成番号',
  order_time: '完成の受け取り時間',
  price: "単価",
  order_total_price: '完成合計',
  scale: '手数料',
  level: '会員レベル',
  level_up: 'メンバーのアップアップ',
  pay_way: 'チャージ方法',
  money_min: '金額が少なすぎる',
  pay_no: 'チャージ方法を選択してください',
  // 新加
  team_all: '全部',
  team_yi: 'レベル1',
  team_er: 'レベル2',
  team_san: 'レベル 3',
  close_order: '完成をキャンセルする',
  shouru: '収入',
  zhichu: '支出',
  welcome: 'いらっしゃいませ',
  order_kong: '完成番号を空にすることはできません',
  quer: '確認する',
  quxiao: 'キャンセル',
  qianbaodi: 'ウォレットアドレス',
  xingming: 'お名前',
  bank_chong: '繰り返しバインドすることはできません',
  introduce: '会社概要',
  platform: '注意事項',
  tixianzhang: '引き出しマイページ',
  xuanze: '選んでください',
  xiayiye: 'クリックして次のページを読み込みます',
  // 新加2
  certificate: '証明書',
  faq: 'faq',
  tc: 'tc',
  guanyu_wm: '私たちについて',
  zuixin_sj: '最新のイベント',
  usdt_tx: 'USDTの出金',
  bank_tx: '銀行カードによる現金引き出し',
  bank_tx_kf: 'オンラインカスタマーサービスにお問い合わせください',
  dongjie_ye: 'バランスを凍結する',
  zanwu_sj: 'データなし',    
  //order Add1
  expected_return: '期待収益',
  order_step_1: '質の高いホテルを揃える',
  order_step_2: '待っている...',
  order_step_3: 'ホテル確定',
}