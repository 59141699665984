module.exports = {
    xinyong: "Credit score",
    chong_zhi_str: "Please contact online customer service for recharge",
    zh_ze: "Total Account Balance",
    vstr_0_0: "Minimum reset ",
    vstr_1_0: "Optimization App",
    vstr_2_0: "3 withdrawals per day",
    vstr_3_0: "Maximum of 3 groups of orders per day",
    vstr_4_0: "Receive a set of 40/40 apps data tasks",
    vstr_5_0: "Maximum ",
    vstr_6_0: " orders per day",
    vstr_7_0: "Profit of ",
    vstr_8_0: " per application",
    vstr_0_1: "Minimum reset ",
    vstr_1_1: "Optimization App",
    vstr_2_1: "5 withdrawals per day",
    vstr_3_1: "Maximum of 4 groups of orders per day",
    vstr_4_1: "Receive a set of 50/50 apps data tasks",
    vstr_5_1: "Maximum ",
    vstr_6_1: " orders per day",
    vstr_7_1: "Profit of ",
    vstr_8_1: " per application",
    vstr_0_2: "Minimum reset ",
    vstr_1_2: "Optimization App",
    vstr_2_2: "8 withdrawals per day",
    vstr_3_2: "Maximum of 4 groups of orders per day",
    vstr_4_2: "Receive a set of 55/55 apps data tasks",
    vstr_5_2: "Maximum ",
    vstr_6_2: " orders per day",
    vstr_7_2: "Profit of ",
    vstr_8_2: " per application",
    vstr_0_3: "Minimum reset ",
    vstr_1_3: "Optimization App",
    vstr_2_3: "10 withdrawals per day",
    vstr_3_3: "Maximum of 5 groups of orders per day",
    vstr_4_3: "Receive a set of 60/60 apps data tasks",
    vstr_5_3: "Maximum ",
    vstr_6_3: " orders per day",
    vstr_7_3: "Profit of ",
    vstr_8_3: " per application",
    copy_stredit: "Edit this and contact service",
    copy_str: "Copy",
    hd_yj: "Earn Commission",
    ky_ye: "Available Balance",
    banner1_text: 'Life is too short to spend time with people who suck the happiness out of you. If someone wants you in their life, they’ll make room for you.',
    leistungsumfang: 'Leistungsumfang',
    leistungsumfang_text: 'Currently serving 100.000+ custormers and receiving good reviews',
    leistungsumfang_free: 'FREE TRANSFER',
    home: 'Home',
    about: 'About',
    order: 'Order',
    record: 'Order Record',
    user: 'My ',
    number_x: 'Quantity',

    about_text1: 'quality goods',
    about_text2: 'More products',
    order_title: 'Task',
    zongzichan: 'Total Assets',
    jinridan: 'Order Quantity',
    tiyanjin: 'Trial Fund',
    jinrishou: `Today's Commission`,
    jintuandui: `Today's Team Commission`,
    dianjigou: 'Start Task',
    adianjigou: 'Submit',
    order_record: 'Order Records',
    all: 'All',
    undone: 'Incomplete',
    completed: 'Completed',
    score: 'Score',
    benutzer: 'User',
    chongzhi: 'Deposit',
    tixian: 'Withdrawal',
    details: 'Account Details',
    password: 'Login Password',
    password_jy: 'Transaction Password',
    notify: 'System Notification',
    kefu: 'Customer Service',
    bank_card: 'Bind Address',
    invite: 'Invite Friends',
    team: 'Team Report',
    language: 'Select Language',
    member: 'Membership Upgrade',
    balance: 'Account Balance',
    quit: 'Logout',
    invite_text1: 'Invite Friends to Earn Cash',
    invite_code: 'Invitation Code',
    invite_btn: 'Invitation Link: Click to Copy',
    copy_s: 'Copy Successful',
    copy_b: 'Copy Failed',
    grade: 'Membership Level',
    my_money: 'My Account Balance',
    open_member: 'Open Membership',
    withdraw_num: 'Number of Withdrawals',
    day: 'Day',
    withdraw_quota: 'Withdrawal Limit',
    order_num: 'Number of Orders Received',
    profit_scale: 'Commission Rate',
    member_time: 'Membership Permanently Valid',
    confirm_pay: 'Confirm Payment',
    order_sub: 'Submit Order',
    user_info: 'User Information',
    avatar: 'Avatar',
    username: 'Username',
    set_up: 'Set Up Now',
    revise_name: 'Modify Name',
    username_hint: 'Please Enter Username',
    user_account: 'User Account',
    add_money: 'Add Money',
    add_money_num: 'Amount to Recharge',
    add_money_hint1: '1. Payment amount must match the order amount, otherwise it will not arrive automatically.',
    add_money_hint2: '2. If recharge or withdrawal is not received, please consult your superior or customer service for resolution.',
    add_money_hint3: 'Contact customer service for fees.',
    add_money_record: 'Recharge Records',
    withdraw_record: 'Withdrawal Records',
    withdraw_money: 'Withdrawal Amount',
    can_withdraw_money: 'Available Withdrawable Amount',
    order_number: 'SN',
    money: 'Amount',
    bank: 'BANK',
    transaction_flow: 'Transaction ID',
    already_paid: 'I have paid',
    upload_pay_img: 'Upload Payment Screenshot',
    order_details: 'Account Details',
    old_password: 'Old Password',
    new_password: 'New Password',
    repeat_password: 'Repeat Password',
    enter: 'Please Enter',
    password_hint: 'Please remember your password. If you forget it, please contact customer service.',
    submit: 'Submit',
    bankCard: 'Bind Address',
    bank: 'Bank',
    bank_card_num: 'Bank Card Number',
    bank_card_name: 'Cardholder Name',
    team: 'Team Report',
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'This Week',
    scale: 'Profit',
    team_people: 'Team Size',
    team_order_scale: 'Team Order Commission',
    open_bank_name: 'Account Holder Name',
    phone: 'Phone Number',
    user_password: 'Password',
    login: 'Log In',
    register: 'Register',
    password_qr: 'Confirm Password',
    pwd_no_same: 'Passwords Do Not Match',
    loading: 'Loading',
    freeze: 'Freeze',
    pending: 'Pending Amount',
    order_hao: 'Order Number',
    order_time: 'Order Time',
    price: 'Unit Price',
    order_total_price: 'Total Order Amount',
    level: 'Membership Level',
    level_up: 'Upgrade Membership',
    pay_way: 'Payment Method',
    money_min: 'Amount Too Small',
    pay_no: 'Please Select Payment Method',
    // New Additions
    team_all: 'All',
    team_yi: 'Level One',
    team_er: 'Level Two',
    team_san: 'Level Three',
    close_order: 'Cancel Order',
    shouru: 'Income',
    zhichu: 'Expenditure',
    welcome: 'Welcome',
    order_kong: 'Order Number Cannot Be Empty',
    quer: 'Confirm',
    quxiao: 'Cancel',
    qianbaodi: 'Wallet Address',
    xingming: 'Name',
    bank_chong: 'Cannot Bind Repeatedly',
    introduce: 'Company Introduction',
    platform: 'Platform Rules',
    tixianzhang: 'Withdrawal Account',
    xuanze: 'Please Select',
    xiayiye: 'Click to Load Next Page',
    // Newly Added 2
    certificate: 'Certificate',
    faq: 'FAQ',
    tc: 'T&C',
    guanyu_wm: 'About Us',
    zuixin_sj: 'Latest Events',
    usdt_tx: 'USDT Withdrawal',
    bank_tx: 'Bank Card Withdrawal',
    bank_tx_kf: 'Please Contact Online Customer Service',
    dongjie_ye: 'Frozen Balance',
    zanwu_sj: 'No data',
    //order Add1
    expected_return: 'Expected return',
    order_step_1: 'Matching quality hotels',
    order_step_2: 'Waiting...',
    order_step_3: 'Hotel confirmed',
}